var rAF = require("./rAF");
var Masonry = require("masonry-layout");

var FaqGrid = {
  msnry: [],
  init: function() {
    var faqGrids = document.querySelectorAll(".categorized-faqs");
    for (var i = 0; i < faqGrids.length; i++) {
      var grid = faqGrids[i];
      this.msnry.push(
        new Masonry(grid, {
          columnWidth: ".subcategory-sizer",
          itemSelector: ".subcategory-col",
          percentPosition: true
        })
      );
    }
  },
  reflow: function() {
    var _this = this;
    if (this.msnry.length)
      rAF(function() {
        _this.msnry.forEach(function(msnry) {
          msnry.layout();
        });
      });
  }
};
module.exports = FaqGrid;
