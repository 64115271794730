const FaqGrid = require("./faq-grid");

$(".faq-btn").on("click", function() {
  // Set clicked button as only active button
  $(".faq-btn").removeClass("active");
  $(this).toggleClass("active");

  // Use the active button's data type to turn on matching faq section
  var faqType = $(this).data("faq-type");
  var section = $(".faq-section[data-faq-type=" + faqType + "]");

  $(".faq-section").removeClass("active");
  $(section).toggleClass("active");

  // Clear any selections from previous toggles
  $(".faq-section[data-faq-type='category'] .category").removeClass("active");

  // If faq section, focus the search bar
  if (faqType == "keyword") {
    $("form.swiftype input").focus();
    $("form.swiftype input").val(function(index, val) {
      return "FAQs";
    });
  }

  if (faqType == "directory") {
    FaqGrid.reflow();
  }
});

$(".faq-section[data-faq-type='category'] .category-btn").on(
  "click",
  function() {
    $(".faq-section[data-faq-type='category'] .category-btn").removeClass(
      "active"
    );
    $(this).toggleClass("active");
    updateCategory($(this).data("faq-category"));
    FaqGrid.reflow();
  }
);

function updateCategory(category) {
  $(".faq-section[data-faq-type='category'] .category").removeClass("active");
  $(
    ".faq-section[data-faq-type='category'] .category[data-faq-category='" +
      category +
      "']"
  ).toggleClass("active");
}

FaqGrid.init();
$(document).on("load", function() {
  FaqGrid.reflow();
});
window.FaqGrid = FaqGrid;
